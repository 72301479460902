import {Component, OnInit} from '@angular/core';
import {ImageService} from '../../services/image.service';
import {ImageRes} from '../../utils/common';
import {SeoService} from '../../services/seo.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.sass']
})
export class IndexComponent implements OnInit {
  imageType = [{
    type: 'carousel',
    name: '轮播图'
  }, {
    type: 'card',
    name: '卡片'
  }, {
    type: 'banner',
    name: '旗帜'
  }];
  carousel: ImageRes[] = [];
  card: ImageRes[] = [];
  banner: ImageRes[] = [];

  constructor(
    private imageServer: ImageService,
    private seo: SeoService
  ) {
    this.setPageSeo();
    this.imageServer.getImageList(1, this.imageType[0].type).subscribe(res => {
      this.carousel = res.data;
    });
    this.imageServer.getImageList(1, this.imageType[1].type).subscribe(res => {
      this.card = res.data;
    });
    this.imageServer.getImageList(1, this.imageType[2].type).subscribe(res => {
      this.banner = res.data;
    });
  }

  setPageSeo() {
    this.seo.setTitle('首页');
    const tmpSeo = {
      description: 'Another Indie 是一家由“骨灰级游戏玩家及技术大咖”组成的游戏发行团队，' +
        '我们致力于寻找最优秀的独立游戏，并帮助开发者把游戏发行到全球范围。我们的团队成员来自世界各地，' +
        '我们也有着专业的游戏发行经验，让你的游戏不再会因为语言、媒体、运营、推广渠道等问题而埋没。如果你的游戏足够优秀，那么我们乐于发现……',
    };
    this.seo.setTags(tmpSeo);
  }

  ngOnInit() {
  }

}
