import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {IndexModule} from './index/index.module';
import { InfoDetailComponent } from './info-detail/info-detail.component';
import {
    NzButtonModule,
    NzCardModule,
    NzDividerModule,
    NzGridModule,
    NzIconModule,
    NzListModule,
    NzPaginationModule,
    NzStatisticModule
} from 'ng-zorro-antd';
import {MomentModule} from 'ngx-moment';
import { InfoListComponent } from './info-list/info-list.component';
import {NguCarouselModule} from '@ngu/carousel';
import {RouterModule} from '@angular/router';
import {ShareModule} from '../share/share.module';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { GameDetailComponent } from './game-detail/game-detail.component';
import { GameCarouselComponent } from './game-detail/game-carousel/game-carousel.component';
import { GameListComponent } from './game-list/game-list.component';



@NgModule({
  declarations: [InfoDetailComponent, InfoListComponent, GameDetailComponent, GameCarouselComponent, GameListComponent],
    imports: [
        CommonModule,
        IndexModule,
        NzGridModule,
        NzStatisticModule,
        NzIconModule,
        MomentModule,
        NzButtonModule,
        NzCardModule,
        NzListModule,
        NguCarouselModule,
        RouterModule,
        NzPaginationModule,
        ShareModule,
        ScrollingModule,
        NzDividerModule,
    ],
  exports: [
    IndexModule,
    CommonModule,
    InfoDetailComponent,
    GameDetailComponent
  ]
})
export class PagesModule { }
