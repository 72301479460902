import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef
} from '@angular/core';
import {ImageRes} from '../../../utils/common';
import {NzIconService} from 'ng-zorro-antd';

@Component({
  selector: 'app-ai-nav',
  templateUrl: './ai-nav.component.html',
  styleUrls: ['./ai-nav.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AiNavComponent implements OnInit {

  show = true;
  // 是否垂直
  inline = false;
  // 下拉菜单
  dropShow = false;
  constructor(
    private nzIconServe: NzIconService,
  ) {
    this.nzIconServe.fetchFromIconfont({
      scriptUrl: '/assets/iconfont/iconfont.js'
    });
  }

  onCollapsedChange($event): void {
    this.show = !$event;
    this.inline = $event;
  }

  onShow() {
    this.show = !this.show;
  }

  ngOnInit() {
  }

  /**
   * 菜单显示状态改变
   * @author（作者）  zozero
   * @date（时间）    2020/4/18 14:49
   * @param（参数）
   * @return（返回值）
   */
  onVisibleChange() {
    this.dropShow = !this.dropShow;
  }

}
