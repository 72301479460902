import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ai-test',
  templateUrl: './ai-test.component.html',
  styleUrls: ['./ai-test.component.sass']
})
export class AiTestComponent implements OnInit {
  isCollapsed = false;
  constructor() { }

  ngOnInit() {
  }

}
