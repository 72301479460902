import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Game, Games} from '../utils/game';
import {API_CONFIG} from './services.module';
import * as queryString from 'querystring';

@Injectable({
  providedIn: 'root'
})
export class GameService {

  constructor(
    private http: HttpClient,
    @Inject(API_CONFIG) private uri: string
  ) {
  }

  /**
   * 游戏列表
   * @author（作者）  zozero
   * @param（参数）
   * @return（返回值）
   */
  gameList(): Observable<Games> {
    const params = new HttpParams({fromString: queryString.stringify({page: 1, per: 10000})});
    return this.http.get(this.uri + 'game', {params}).pipe(map((res: Games) => res));
  }

  /**
   * 游戏详情
   * @author（作者）  zozero
   * @param（参数）
   * @return（返回值）
   */
  gameShow(id: string): Observable<Game> {
    return this.http.get(this.uri + 'game/' + id).pipe(map((res: { data: Game }) => res.data));
  }
}
