import {Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {AboutUs} from '../../../utils/common';
import {AboutUsService} from '../../../services/about-us.service';

@Component({
  selector: 'app-index-about-us',
  templateUrl: './index-about-us.component.html',
  styleUrls: ['./index-about-us.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndexAboutUsComponent implements OnInit {
  about = '';

  constructor(
    private aboutServe: AboutUsService,
    private cdr: ChangeDetectorRef
  ) {
    this.aboutServe.getAboutUs().subscribe(res => {
      this.about = res.content;
      this.cdr.markForCheck();
    });
  }

  ngOnInit() {
  }

}
