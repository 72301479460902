import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpParams, HttpRequest} from '@angular/common/http';
import {ImageList, ImageRes, Pagination} from '../utils/common';
import {API_CONFIG} from './services.module';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor(
    private http: HttpClient,
    @Inject(API_CONFIG) private uri: string
  ) {
  }

  /**
   * 获取所有类型为info的图片
   * @author（作者）  zozero
   * @date（时间）    2020/4/15 6:57
   * @param（参数）
   * @return（返回值）
   */
  getImageList(page: number, type = '', trashed = '', per = 10): Observable<ImageList> {
    const params = new HttpParams()
      .set('filter[type]', type)
      .set('filter[trashed]', trashed)
      .set('page', page.toString())
      .set('per', per.toString());
    return this.http.get(this.uri + 'image', {params}).pipe(map((res: ImageList) => res));
  }

  /**
   * 获取单个画册
   * @author（作者）  zozero
   * @date（时间）    2020/4/22 9:59
   * @param（参数）
   * @return（返回值）
   */
  show(id) {
    return this.http.get(this.uri + 'image/' + id).pipe(map((res: { data: ImageRes }) => res.data));
  }
}
