import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';
import {ImageRes} from '../../../utils/common';

@Component({
  selector: 'app-index-game-banners',
  templateUrl: './index-game-banners.component.html',
  styleUrls: ['./index-game-banners.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndexGameBannersComponent implements OnInit {
  @Input() banner: ImageRes[];
  constructor() { }

  ngOnInit() {
  }

}
