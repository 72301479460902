import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {IndexComponent} from './pages/index/index.component';
import {InfoDetailComponent} from './pages/info-detail/info-detail.component';
import {InfoListComponent} from './pages/info-list/info-list.component';
import {GameDetailComponent} from './pages/game-detail/game-detail.component';
import {GameListComponent} from './pages/game-list/game-list.component';

const routes: Routes = [
  {path: '', component: IndexComponent, data: {title: '首页'}},
  {path: 'info/list', component: InfoListComponent, data: {title: '资讯列表'}},
  {path: 'info/detail/:id', component: InfoDetailComponent, data: {title: '资讯详情'}},
  {path: 'game/detail/:id', component: GameDetailComponent, data: {title: '游戏详情'}},
  {path: 'game/list', component: GameListComponent, data: {title: '游戏列表'}},
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
