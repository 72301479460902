import {Component, ElementRef, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {switchMap} from 'rxjs/operators';
import {Game, GamesImage} from '../../utils/game';
import {GameService} from '../../services/game.service';
import {InfoService} from '../../services/info.service';
import {SeoService} from '../../services/seo.service';

@Component({
  selector: 'app-game-detail',
  templateUrl: './game-detail.component.html',
  styleUrls: ['./game-detail.component.sass']
})
export class GameDetailComponent implements OnInit {
  id: string;
  game: Game;
  images: GamesImage[] = [];

  constructor(
    private route: ActivatedRoute,
    private gameServe: GameService,
    private seo: SeoService
  ) {
    this.getGameDetail();

  }

  // 设置当前页的seo
  setPageSeo() {
    this.seo.setTitle(this.game.name);
    const tmpSeo = {
      keywords: '独立游戏、活动、激活码、优惠、介绍',
      description: this.game.name
    };
    this.seo.setTags(tmpSeo);
  }

  ngOnInit() {
  }

  // 获得游戏详情数据
  getGameDetail() {
    const gameData = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
          this.id = params.get('id');
          return this.gameServe.gameShow(this.id);
        }
      ));
    gameData.subscribe(res => {
      this.game = res;
      this.images = res.games_images;
      this.setPageSeo();
    });
  }
}
