import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {NguCarouselConfig} from '@ngu/carousel';
import {GamesImage} from '../../../utils/game';

@Component({
  selector: 'app-game-carousel',
  templateUrl: './game-carousel.component.html',
  styleUrls: ['./game-carousel.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GameCarouselComponent implements OnInit, OnChanges  {
  @Input() images: GamesImage[] = [];
  // 轮播图配置
  public carouselTile: NguCarouselConfig = {
    grid: {xs: 1, sm: 1, md: 1, lg: 1, all: 0},
    slide: 1,
    speed: 400,
    interval: {
      timing: 3000,
      initialDelay: 1000
    },
    point: {
      visible: true
    },
    load: 2,
    loop: true,
    touch: true
  };

  constructor(
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.images) {
      this.cdr.markForCheck();
    }
  }
}
