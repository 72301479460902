import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {map, switchMap} from 'rxjs/operators';
import {InfoService} from '../../services/info.service';
import {Info} from '../../utils/info';
import {SeoService} from '../../services/seo.service';

@Component({
  selector: 'app-info-detail',
  templateUrl: './info-detail.component.html',
  styleUrls: ['./info-detail.component.sass']
})
export class InfoDetailComponent implements OnInit {
  info: Info;
  praise = 'outline';
  private id: string;

  constructor(
    private route: ActivatedRoute,
    private infoServe: InfoService,
    private seo: SeoService
  ) {

    const infoData = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
          this.id = params.get('id');
          return this.infoServe.show(this.id);
        }
      ));
    infoData.subscribe(res => {
      this.info = res;
      this.setPageSeo();
    });
  }
  // 设置当前页的seo
  setPageSeo() {
    this.seo.setTitle(this.info.title);
    const tmpSeo = {
      keywords: '独立游戏资讯详情、独立游戏资讯',
      description: this.info.excerpt
    };
    this.seo.setTags(tmpSeo);
  }
  ngOnInit() {
    if (!this.isPraise()) {
      this.praise = 'fill';
    }
  }

  // 设置点赞
  setPraise() {
    if (!this.isPraise()) {
      return;
    }
    this.infoServe.changePraise(this.id).subscribe(res => {
      this.recordPraise();
      this.praise = 'fill';
      this.infoServe.show(this.id).subscribe(data => {
        this.info = data;
      });
    });
  }

  // 将点赞记录保存到本地
  recordPraise() {
    let infoLocalArr = [];
    const infoLocal = localStorage.getItem('info');
    if (infoLocal) {
      infoLocalArr = infoLocal.split(',');
    }
    infoLocalArr.push(this.id);
    localStorage.setItem('info', infoLocalArr.toString());
  }

  // 判断是否点过赞
  isPraise() {
    let infos = [];
    const infoLocal = localStorage.getItem('info');
    if (infoLocal) {
      infos = infoLocal.split(',');
    }
    return infos.findIndex(id => id === this.id);
  }
}
