import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndexComponent } from './index.component';
import {ShareModule} from '../../share/share.module';
import { IndexCarouselComponent } from './index-carousel/index-carousel.component';
import {NzCarouselModule, NzGridModule, NzIconModule} from 'ng-zorro-antd';
import { IndexGameCardsComponent } from './index-game-cards/index-game-cards.component';
import { IndexGameBannersComponent } from './index-game-banners/index-game-banners.component';
import { IndexAboutUsComponent } from './index-about-us/index-about-us.component';
import { IndexLanguageComponent } from './index-language/index-language.component';
import {NguCarouselModule} from '@ngu/carousel';


@NgModule({
  // tslint:disable-next-line:max-line-length
  declarations: [IndexComponent, IndexCarouselComponent, IndexGameCardsComponent, IndexGameBannersComponent, IndexAboutUsComponent, IndexLanguageComponent],
  imports: [
    CommonModule,
    ShareModule,
    NzCarouselModule,
    NzGridModule,
    NguCarouselModule,
    NzIconModule
  ],
  exports: [
    IndexCarouselComponent
  ]
})
export class IndexModule { }
