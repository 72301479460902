import {Inject, Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpParams, HttpRequest} from '@angular/common/http';
import {AboutUs} from '../utils/common';
import {API_CONFIG} from './services.module';

@Injectable({
  providedIn: 'root'
})
export class AboutUsService {

  constructor(
    private http: HttpClient,
    @Inject(API_CONFIG) private uri: string
  ) {
  }

  /**
   * 关于我们
   * @author（作者）  zozero
   * @param（参数）
   * @return（返回值）
   */
  getAboutUs(): Observable<AboutUs> {
    return this.http.get(this.uri + 'aboutus').pipe(map((res: { data: AboutUs }) => res.data));
  }
}
