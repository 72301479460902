import { Component } from '@angular/core';
import {ImageRes} from './utils/common';
import {ImageService} from './services/image.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  game: ImageRes[] = [];
  constructor(
    private imageServer: ImageService
  ) {
    this.imageServer.getImageList(1, 'game').subscribe(res => {
      this.game = res.data;
    });
  }
}
