import {Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, ChangeDetectorRef, SimpleChanges} from '@angular/core';
import {ImageRes} from '../../../utils/common';

@Component({
  selector: 'app-index-game-cards',
  templateUrl: './index-game-cards.component.html',
  styleUrls: ['./index-game-cards.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndexGameCardsComponent implements OnInit, OnChanges {
  @Input() card: ImageRes[];

  constructor(
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.images) {
      this.cdr.markForCheck();
    }
  }
}
