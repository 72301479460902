import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AiNavComponent} from './ai-ui/ai-nav/ai-nav.component';
import {
  NzBadgeModule,
  NzButtonModule,
  NzDropDownModule,
  NzGridModule,
  NzIconModule,
  NzLayoutModule,
  NzMenuModule,
  NzToolTipModule
} from 'ng-zorro-antd';
import { AiTestComponent } from './ai-ui/ai-test/ai-test.component';
import { FooterComponent } from './footer/footer.component';
import {RouterModule} from '@angular/router';
import { SafeHtmlPipe } from './pipe/safe-html.pipe';


@NgModule({
  declarations: [AiNavComponent, AiNavComponent, AiTestComponent, FooterComponent, SafeHtmlPipe],
  imports: [
    CommonModule,
    NzMenuModule,
    NzIconModule,
    NzBadgeModule,
    NzDropDownModule,
    NzButtonModule,
    NzLayoutModule,
    NzGridModule,
    RouterModule,
    NzToolTipModule
  ],
  exports: [
    AiNavComponent,
    AiTestComponent,
    FooterComponent,
    SafeHtmlPipe
  ]
})
export class ShareModule {
}
