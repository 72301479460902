import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-index-language',
  templateUrl: './index-language.component.html',
  styleUrls: ['./index-language.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndexLanguageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
