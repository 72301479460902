import {Component, OnInit, ChangeDetectionStrategy} from '@angular/core';
import {NzIconService} from 'ng-zorro-antd';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit {

  constructor(
    private nzIconServe: NzIconService
  ) {
    this.nzIconServe.fetchFromIconfont({
      scriptUrl: '/assets/iconfont/iconfont.js'
    });
  }

  ngOnInit() {
  }

}
