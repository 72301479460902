import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Info, Infos} from '../utils/info';
import * as queryString from 'querystring';
import {map} from 'rxjs/operators';
import {API_CONFIG} from './services.module';

@Injectable({
  providedIn: 'root'
})
export class InfoService {

  constructor(
    private http: HttpClient,
    @Inject(API_CONFIG) private uri: string
  ) {
  }

  /**
   * 资讯列表
   * @author（作者）  zozero
   * @date（时间）    2020/4/10 22:35
   * @param（参数）
   * @return（返回值）
   */
  list(page: number, sort = '', per = 12): Observable<Infos> {
    const params = new HttpParams({fromString: queryString.stringify({page, per, include: 'manager', sort})});
    return this.http.get(this.uri + 'info', {params}).pipe(map((res: Infos) => res));
  }

  /**
   * 资讯详情
   * @author（作者）  zozero
   * @date（时间）    2020/4/10 22:35
   * @param（参数）
   * @return（返回值）
   */
  show(id: string): Observable<Info> {
    return this.http.get(this.uri + 'info/' + id).pipe(map((res: { data: Info }) => res.data));
  }

  /**
   * 点赞
   * @author（作者）  zozero
   * @date（时间）    2020/4/10 22:35
   * @param（参数）
   * @return（返回值）
   */
  changePraise(id: string): Observable<any> {
    return this.http.get(this.uri + 'praise/' + id);
  }
}
