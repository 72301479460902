import {Injectable} from '@angular/core';
import {Title, Meta} from '@angular/platform-browser';
import {Seo} from '../utils/common';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  private commonKeywords = '、游戏、玩家、骨灰级玩家、技术大咖、独立游戏、游戏发行、游戏语言、游戏媒体、游戏运营、游戏推广、AnotherIndie';
  private commonDescriptions = '';
  private commonTitle = '-AnotherIndie-独立游戏发行';

  constructor(
    private meta: Meta,
    private title: Title,
  ) {
  }

  setTitle(title: string) {
    this.title.setTitle(title + this.commonTitle);
  }

  setTags(seo: Seo) {
    this.meta.addTags([
      {name: 'description', content: this.commonDescriptions + seo.description},
      {name: 'keywords', content: seo.keywords + this.commonKeywords},
    ]);
  }
}
