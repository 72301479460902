import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {NzIconService} from 'ng-zorro-antd';
import {ImageService} from '../../services/image.service';
import {ImageRes} from '../../utils/common';
import {NguCarouselConfig} from '@ngu/carousel';
import {InfoService} from '../../services/info.service';
import {Info, Infos} from '../../utils/info';
import {fromEvent} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {SeoService} from '../../services/seo.service';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-info-list',
  templateUrl: './info-list.component.html',
  styleUrls: ['./info-list.component.sass']
})
export class InfoListComponent implements OnInit {
  // 资讯轮播图
  information: ImageRes[] = [];
  // 轮播图配置
  public carouselTile: NguCarouselConfig = {
    grid: {xs: 1, sm: 1, md: 1, lg: 1, all: 0},
    slide: 1,
    speed: 400,
    interval: {
      timing: 3000,
      initialDelay: 1000
    },
    point: {
      visible: true
    },
    load: 2,
    loop: true,
    touch: true
  };
  infoData: Infos;
  // 资讯列表
  infoList: Info[] = [];
  // 推荐
  recommend: Info[] = [];
  @ViewChild('cusScroll', {static: true}) cusScroll: ElementRef<any>;
  showTip = false;

  constructor(
    private nzIconServe: NzIconService,
    private imageServer: ImageService,
    private infoServe: InfoService,
    private seo: SeoService,
    @Inject(DOCUMENT) private doc: Document
  ) {
    this.setPageSeo();
    this.nzIconServe.fetchFromIconfont({
      scriptUrl: '/assets/iconfont/iconfont.js'
    });
    this.imageServer.getImageList(1, 'information').subscribe(res => {
      this.information = res.data;
    });
    this.getInfoList();
    this.getRecommend();
  }

  setPageSeo() {
    this.seo.setTitle('最新游戏资讯');
    const tmpSeo = {
      keywords: '最新资讯、独立游戏最新、游戏资讯',
      description: '最新独立游戏资讯等你来看，了解业界独立游戏资讯。'
    };
    this.seo.setTags(tmpSeo);
  }

  ngOnInit() {
    fromEvent(this.doc, 'scroll').pipe(debounceTime(200)).subscribe((event: any) => {
      if (event.target.scrollingElement.clientWidth < 768) {
        // tslint:disable-next-line:max-line-length
        const bottom = event.target.scrollingElement.scrollHeight - event.target.scrollingElement.clientHeight - event.target.scrollingElement.scrollTop;
        if (bottom < 520 && this.infoData) {
          this.infoData.meta.current_page++;
          if (this.infoData.meta.current_page > this.infoData.meta.last_page) {
            this.showTip = true;
            return;
          }
          this.infoServe.list(this.infoData.meta.current_page).subscribe(res => {
            this.infoData = res;
            this.infoList = this.infoList.concat(res.data);
          });
        }
      }
    });
  }

  // 获得资讯列表
  // page 页码
  getInfoList(page = 1) {
    this.showTip = false;
    this.infoServe.list(page).subscribe(res => {
      this.infoList = res.data;
      this.infoData = res;
    });
  }

  // 推荐
  getRecommend() {
    this.infoServe.list(1, 'order', 10).subscribe(res => {
      this.recommend = res.data;
    });
  }
}
