import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {Game, Games} from '../../utils/game';
import {GameService} from '../../services/game.service';
import {SeoService} from '../../services/seo.service';
import {fromEvent} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-game-list',
  templateUrl: './game-list.component.html',
  styleUrls: ['./game-list.component.sass']
})
export class GameListComponent implements OnInit {
  gameData: Games;
  // 游戏列表
  gameList: Game[] = [];
  @ViewChild('cusScroll', {static: true}) cusScroll: ElementRef<any>;
  showTip = false;

  constructor(
    private gameServe: GameService,
    private seo: SeoService,
    @Inject(DOCUMENT) private doc: Document
  ) {
    this.getGameList();
    this.setPageSeo();
  }

  ngOnInit() {
  }

  setPageSeo() {
    this.seo.setTitle('独立游戏列表');
    const tmpSeo = {
      keywords: '最新游戏、游戏折扣、独立游戏、游戏优惠、游戏激活',
      description: '最新独立游戏等你来看，独立游戏激活码。'
    };
    this.seo.setTags(tmpSeo);
  }

  getGameList() {
    this.gameServe.gameList().subscribe(res => {
      this.gameData = res;
      this.gameList = res.data;
    });
  }
}
